<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none text-center" to="/">
     <img  src="brand_dark.png" class="c-sidebar-brand-full" height="48"/>
    </CHeaderBrand>
    <CHeaderNav class="mr-4 ml-lg-auto ml-md-0"> 
  <p class="d-none d-lg-block mt-3 text-muted">  Hi, <strong>{{$store.state.current_user ? $store.state.current_user.first_name + " " + $store.state.current_user.last_name : null}}</strong>!</p>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
  },
};
</script>
