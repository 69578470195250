export default [
  {
    _name: "CSidebarNav",
    _children: [     
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "Dashboard",
        permissions: [],
      },

      {
        _name: "CSidebarNavDropdown",
        name: "User Roles",
        route: "/role",
        icon: "Roles",
        permissions: ["role_create","role_read","role_update","role_delete" ],
        items: [
          {
            name: "Add Roles",
            to: "/role/create",
            icon: "Plus",
            permissions: ["role_create"],
          },

          {
            name: "Manage Roles",
            to: "/roles",
            icon: "ManageIcon",
            permissions: ["role_update", "role_read", "role_delete"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "User",
        route: "/user",
        icon: "User",
        permissions: ["user_create","user_update",'user_delete',"user_read"],
        items: [
          {
            name: "Add User",
            to: "/user/create",
            icon: "Plus",
            permissions: ["user_create"],
          },

          {
            name: "Manage Users",
            to: "/users",
            icon: "ManageIcon",
            permissions: ["user_update",'user_delete',"user_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Shop",
        route: "/shop",
        icon: "Shop",
        permissions: [],
        items: [
          {
            name: "Add Shop",
            to: "/shop/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage Shop",
            to: "/shops",
            icon: "ManageIcon",
            permissions:[],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Supplier",
        route: "/supplier",
        icon: "Supplier",
        permissions: ["supplier_update",'supplier_delete',"supplier_read"],
        items: [
          {
            name: "Add Supplier",
            to: "/supplier/create",
            icon: "Plus",
            permissions: ["supplier_create"],
          },

          {
            name: "Manage Supplier",
            to: "/suppliers",
            icon: "ManageIcon",
            permissions: ["supplier_update",'supplier_delete',"supplier_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Customer",
        route: "/customer",
        icon: "Customer",
        permissions: ["customer_create","customer_update",'customer_delete',"customer_read"],
        items: [
          {
            name: "Add Customer",
            to: "/customer/create",
            icon: "Plus",
            permissions: ["customer_create"],
          },

          {
            name: "Manage Customer",
            to: "/customers",
            icon: "ManageIcon",
            permissions: ["customer_update",'customer_delete',"customer_read"],
          },
        ],
      },
   
      {
        _name: "CSidebarNavDropdown",
        name: "Service",
        route: "/service",
        icon: "Service",
        permissions: [],
        items: [
          {
            name: "Add Service",
            to: "/service/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage Service",
            to: "/services",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Product Group",
        route: "/product/group",
        icon: "ProductGroup",
         permissions: ["product_group_update",'product_group_delete',"product_group_read"],
        items: [
          {
            name: "Add Product Group",
            to: "/product/group/create",
            icon: "Plus",
             permissions: ["product_group_create"],
          },

          {
            name: "Manage Product Group",
            to: "/product/groups",
            icon: "ManageIcon",
             permissions: ["product_group_update",'product_group_delete',"product_group_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Brand",
        route: "/brand",
        icon: "Brand",
         permissions: ["brand_update",'brand_delete',"brand_read"],
        items: [
          {
            name: "Add Brand",
            to: "/brand/create",
            icon: "Plus",
             permissions: ["brand_create"],
          },

          {
            name: "Manage Brand",
            to: "/brands",
            icon: "ManageIcon",
             permissions: ["brand_update",'brand_delete',"brand_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Product Category",
        route: "/product/categor",
        icon: "ProductCategory",
        permissions: ["product_category_update",'product_category_delete',"product_category_read"],
        items: [
          {
            name: "Add Product Category",
            to: "/product/category/create",
            icon: "Plus",
            permissions: ["product_category_create"],
          },

          {
            name: "Manage Product Category",
            to: "/product/categories",
            icon: "ManageIcon",
            permissions: ["product_category_update",'product_category_delete',"product_category_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Product Model",
        route: "/product/model",
        icon: "ProductModel",
        permissions: ["product_model_update",'product_model_delete',"product_model_read"],
        items: [
          {
            name: "Add Product Model",
            to: "/product/model/create",
            icon: "Plus",
            permissions: ["product_model_create"],
          },

          {
            name: "Manage Product Model",
            to: "/product/models",
            icon: "ManageIcon",
            permissions: ["product_model_update",'product_model_delete',"product_model_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Product",
        route: "/products",
        icon: "Product",
        permissions: ["product_update",'product_delete',"product_read"],
        items: [
          {
            name: "Add Product",
            to: "/products/create",
            icon: "Plus",
            permissions: ["product_create"],
          },

          {
            name: "Manage Product",
            to: "/products",
            icon: "ManageIcon",
            permissions: ["product_update",'product_delete',"product_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Stock",
        route: "/stock",
        icon: "Warehouse",
        permissions: ["stock_update",'stock_delete',"stock_read"],
        items: [
          {
            name: "Product Stock (Barcode)",
            to: "/stock/add/barcode",
            icon: "BarCode",
            permissions: ["stock_create"],
          },
          {
            name: "Product Stock (Bulk)",
            to: "/stock/add/bulk",
            icon: "Plus",
            permissions: ["stock_create"],
          },

          {
            name: "View Stocks",
            to: "/stocks",
            icon: "ManageIcon",
            permissions: ["stock_update",'stock_delete',"stock_read"],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Order",
        route: "/order",
        icon: "Order",
        permissions: [],
        items: [
          {
            name: "Add Order",
            to: "/order/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage Order",
            to: "/orders",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "B2B Order",
        route: "/b2b_order",
        icon: "BarCode",
        permissions: [],
        items: [
          {
            name: "Add B2B Order",
            to: "/b2b_order/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage B2B Order",
            to: "/b2b_orders",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Proposal",
        route: "/proposal",
        icon: "cilLayers",
        permissions: [],
        items: [
          {
            name: "Add Proposal",
            to: "/proposal/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage Proposal",
            to: "/proposals",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "B2B Proposal",
        route: "/b2b_proposal",
        icon: "cilLayers",
        permissions: [],
        items: [
          {
            name: "Add B2B Proposal",
            to: "/b2b_proposal/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage B2B Proposal",
            to: "/b2b_proposals",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "CRM",
        route: "/crm",
        icon: "CRM",
        permissions: [],
        items: [
          {
            name: "Add Query",
            to: "/crm/create",
            icon: "Plus",
            permissions: [],
          },

          {
            name: "Manage Queries",
            to: "/crms",
            icon: "ManageIcon",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavDropdown",
        name: "Report",
        route: "/report",
        icon: "Report",
        permissions: [],
        items: [
          {
            name: "Sales Reports",
            to: "/report/sale",
            icon: "ReportChild",
            permissions: [],
          },
          {
            name: "Stock Reports",
            to: "/report/stock",
            icon: "ReportChild",
            permissions: [],
          },
          {
            name: "Purchase Reports",
            to: "/report/purchase",
            icon: "ReportChild",
            permissions: [],
          },
          {
            name: "Due Reports",
            to: "/report/due",
            icon: "ReportChild",
            permissions: [],
          },
          {
            name: "Deposit Reports",
            to: "/report/deposit",
            icon: "ReportChild",
            permissions: [],
          },
        ],
      },
      {
        _name: "CSidebarNavItem",
        name: "Marketing",
        to: "/marketing",
        icon: "Marketing",
        permissions: [],
      },
    ],
  },
];
