<template>
  <CFooter :fixed="false">
   
    <div class="mr-auto">
     <em> <span class="mr-1" target="_blank">Developed by</span>
     <strong> <a href="https://softronixs.com/" class="text-info">Softronixs Ltd.</a>  </strong> </em> 
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
