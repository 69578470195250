<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
         <div>
                <img
                  src="img/user_profile/user.png"
                  class="rounded-circle"
                  style="width: 40px; height: 40px; object-fit:cover;"
                />
              </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem @click="user_profile">
        <CIcon name="Profile"/> Profile
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout">
      <CIcon name="LogOut" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import axios from "axios";
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
    };
  },
  methods: {
    user_profile() {
      this.$router.push({ name: "User Profile" }).catch(()=>{});
    },
    logout() {
      if (localStorage.getItem("access_token") == null) {
        return;
      } 
      this.$store.dispatch('logout')     
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>