<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none">
      <img src="brand.png" class="c-sidebar-brand-full" height="60" />
      <img
        src="brand_minimized.png"
        class="c-sidebar-brand-minimized"
        height="60"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="currentItems" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  data(){
    return {
      currentItems: []
    }
  },
  created() {
   this.renderSidebarItems();
  },
  methods: {
    renderSidebarItems() {
      let p = false;
      let c = false;
      const navItems = nav[0]._children.filter((navItem) => {
        // if permission array is empty then add this item.
        if (navItem.permissions.length === 0) {
          p = true;
        }
        // if need permissions
        else if (navItem.permissions.length) {
          navItem.permissions.every((permission) => {
            p = this.$store.state.userPermissions
              ? this.$store.state.userPermissions.includes(permission)
              : false;
            // skipping the loop after getting any permission is matched.
            if (p) {
              return;
            }
          });
        }
        // Otherwise skip this item.
        else {
          p = false;
        }
        //then returning true for add current element in the array
        return p;
      });
      navItems.forEach((navItem) => {
        // If navItem have children items
        if (navItem.items) {
          //  then filter
          navItem.items = navItem.items.filter((child) => {
            //  if child don't need required any permissions
            if (child.permissions.length === 0) {
              c = true; //add this child
            }
            // if child need permissions, permission array have elements
            else if (child.permissions.length) {
              child.permissions.every((permission) => {
                c = this.$store.state.userPermissions
                  ? this.$store.state.userPermissions.includes(permission)
                  : false;
                // skipping the loop after getting any permission is matched.
                if (c) {
                  return;
                }
              });
            }
            // Otherwise skip this item.
            else {
              c = false;
            }
            return c;
          });
        }
      });      
      this.$options.nav[0]._children = navItems;
      this.currentItems = this.$options.nav;
    }
  },
  computed:{
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
};
</script>
